<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            1. Оказание первой помощи пострадавшим при пожаре
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
              height="130"
            >
            <strong>Первая помощь </strong> - комплекс мероприятий, направленных на восстановление или сохранение жизни и здоровья пострадавшего. Ее оказывает тот, кто находится рядом с пострадавшим (взаимопомощь), или сам пострадавший (самопомощь) до прибытия медицинского работника.
          </v-alert>
        </v-col>
        <v-col cols="7">
          <v-card class="pa-5">
            <v-card-title>
              Основные поражающие факторы для человека при пожаре:
            </v-card-title>
            <v-card
            class="pa-2 d-flex justify-center align-center"
            full-width
            flat

            >
              <v-img
                lazy-src="img/material/thermometer.svg"
                max-width="100"
                src="img/material/thermometer.svg"
                class="ma-1"
              ></v-img>
              <v-spacer></v-spacer>
              <v-card-text class="title font-weight-light">
                Высокая температура и риск получения ожогов
              </v-card-text>
            </v-card>
            <v-card
            class="pa-2 d-flex justify-center align-center"
            full-width
            flat

            >
              <v-img
                lazy-src="img/material/co2.svg"
                max-width="100"
                src="img/material/co2.svg"
                class="ma-1"
              ></v-img>
              <v-spacer></v-spacer>
              <v-card-text class="title font-weight-light">
                Выделение токсичных веществ и острые отравления продуктами горения
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card flat class="ptm-img-card">
            <v-img
              lazy-src="img/material/f-help.svg"
              width="100%"
              src="img/material/f-help.svg"
              class="ma-1 ptm-img"
            ></v-img>
          </v-card>
        </v-col>
                <v-col cols="6">
          <v-dialog
            v-model="dialog1"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                outlined
                height="50"
                color="red"
                class="mt-5"
                v-on="on"
                v-bind="attrs"
                >
                  Порядок оказания первой помощи при пожаре
              </v-btn>
            </template>
            <v-card class="pa-5 noselect">
              <v-card-title class="headline">
                Порядок оказания первой помощи при пожаре:
              </v-card-title>
              <v-card-text class="title font-weight-light mb-5 pb-0">
                <v-icon size="70" color="red">mdi-numeric-1</v-icon>
                Убедиться, что опасности от пожара больше нет. При угрозе от огня и дыма эвакуировать пострадавшего. Если эвакуация невозможна, следует устранить угрозу;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-5 pb-0">
                <v-icon size="70" color="red">mdi-numeric-2</v-icon>
                Вызвать скорую медицинскую помощь по телефону 103 или 112;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-5 pb-0">
                <v-icon size="70" color="red">mdi-numeric-3</v-icon>
                Пострадавшего уложить на спину, голову повернуть набок на случай рвоты или внутреннего кровотечения;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-5 pb-0">
                <v-icon size="70" color="red">mdi-numeric-4</v-icon>
                Осмотреть пострадавшего на наличие сознания, пульса, дыхания;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-0 pb-0">
                <v-icon size="70" color="red">mdi-numeric-5</v-icon>
                Выполнить неотложные операции по спасению: остановить артериальное кровотечение, зафиксировать конечности при переломах, сделать искусственное дыхание, непрямой массаж сердца.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog2"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                height="50"
                color="red"
                class="mt-5"
                v-on="on"
                v-bind="attrs"
                >
                  Запрещенные действия при оказании первой помощи
              </v-btn>
            </template>
            <v-card class="pa-5 noselect">
              <v-card-title class="headline mb-5">
                Запрещенные действия при оказании первой помощи:
              </v-card-title>
              <v-card-text class=" d-flex align-center title font-weight-light mb-10 pb-0">
                <v-img
                  lazy-src="img/material/inability-to-move.svg"
                  max-width="100"
                  src="img/material/inability-to-move.svg"
                  class="mr-5"
                ></v-img>
                Перемещать пострадавшего в случае отсутствия угрозы и возможности оказывать помощь на месте. Особенно при переломах, повреждениях позвоночника, ранениях;
              </v-card-text>
              <v-card-text class=" d-flex align-center title font-weight-light mb-10 pb-0">
                <v-img
                  lazy-src="img/material/drugs.svg"
                  max-width="100"
                  src="img/material/drugs.svg"
                  class="mr-5"
                ></v-img>
                Давать воду, лекарства пострадавшему без сознания так как он может задохнуться;
              </v-card-text>
              <v-card-text class=" d-flex align-center title font-weight-light mb-10 pb-0">
                <v-img
                  lazy-src="img/material/hand-blood.svg"
                  max-width="100"
                  src="img/material/hand-blood.svg"
                  class="mr-5"
                ></v-img>
                Удалять инородные тела, выступающие из грудной, брюшной или черепной полости даже если кажется, что их легко можно вытащить;
              </v-card-text>
              <v-card-text class=" d-flex align-center title font-weight-light mb-5 pb-0">
                <v-img
                  lazy-src="img/material/vomiting.svg"
                  max-width="100"
                  src="img/material/vomiting.svg"
                  class="mr-5"
                ></v-img>
                Оставлять находящегося без сознания на спине, чтобы он не захлебнулся в случае рвоты или кровотечения.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
          <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-img-card {
  position: relative;
}
.ptm-img {
  position: absolute;
  top: 100px;
  right: 0;
}
</style>
